<template>
  <div id="responsive">
    <div id="mobile" :class="{mobile: !listView}">
      <div v-for="artist in fullArtists" class="artist" :key="artist.name + 'mobile'">
        <img :src="artist.image" class="img">
        <p>{{ artist.name }}</p>
        <span class="spacer"></span>
        <span class="icon">
          <img :src="require('@/assets/img/spotify icon.png')" />
          <p class="count" :title="artist.realPercent">{{ artist.count }}</p>
        </span>
      </div>
    </div>
    <div v-if="!listView" id="playlist" class="playlist">
      <div v-for="artist in artists" class="artist" :key="artist.name" :style="{'height': `${artist.height}%`}">
        <img :src="artist.image" class="img">
        <p>{{ artist.name }}</p>
        <span class="icon">
          <img :src="require('@/assets/img/spotify icon.png')" />
          <p class="count" :title="artist.realPercent">{{ artist.count }}</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import spotify from "../spotify"
import { mapState } from "vuex"

export default {
  name: 'PlayList',
  computed: mapState(['playlist']),
  props: ['listView'],
  data() {
    return {
      artists: [],
      fullArtists: []
    }
  },
  async mounted() {
    let tracks = []
    let artists = {}
    let res = (await spotify.get(`https://api.spotify.com/v1/playlists/${this.playlist}`)).tracks
    for (let track of res.items) {
      tracks.push(track.track)
    }
    while (res.next) {
      res = await spotify.get(res.next)
      for (let track of res.items) {
        tracks.push(track.track)
      }
    }
    for (let track of tracks) {
      for (let artist of track.artists) {
        if (artists[artist.id]) {
          artists[artist.id].count++
        } else {
          artists[artist.id] = {
            count: 1,
            name: artist.name,
            image: track.album.images[0].url
          }
        }
      }
    }
    let artistsArr = []
    for (let id in artists) {
      artistsArr.push(artists[id])
    }
    artistsArr.sort((a, b) => b.count - a.count)
    this.fullArtists = artistsArr
    for (let artist of artistsArr) {
      artist.realPercent = `${Math.round((artist.count / tracks.length) * 100)}%`
    }
    artistsArr = artistsArr.slice(0, 6)
    const sum = artistsArr.reduce((a, b) => a + b.count, 0)
    for (let artist of artistsArr) {
      artist.percent = Math.log(artist.count / sum)
      artist.height = 100 - (-artist.percent * (100 / Math.log(100)))
    }
    this.artists = artistsArr
  }
}
</script>

<style lang="scss" scoped>
#playlist {
  justify-content: center;
  align-items: flex-end;
  padding: 2em;
  height: 90vh;
}

.artist {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
  padding: 1em;
  background: #dcdcdc;
  flex: 1;
  border-radius: 1em;

  * {
    padding: .5em;
  }

  p {
    font-size: 1.2em;
    text-align: center;
  }

  .count {
    font-size: 1.5em;
  }

  img {
    max-width: 8em;
  }
}

#playlist {
  .artist {
    flex-direction: column;
  }
}

.playlist {
  display: flex;
}

.mobile {
  display: none;
}

#mobile {
  flex-direction: column;
  .artist:not(:last-child) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .playlist {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }
}

@media only screen and (max-width: 700px) {
  .img {
    width: 3em;
  }

  .artist {
    * {
      padding: .25em;
    }
    p:not(.count) {
      font-size: 1em;
    }
  }
}
</style>