<template>
  <div v-if="loaded" id="main">
    <div id="header">
      <h2>Comparing {{ first.name }} to {{ second.name }}</h2>
      <p>{{ first.tracks.length }} unique tracks.</p>
    </div>
    <div v-for="track in first.tracks" class="artist" :key="track.name">
      <img :src="track.album.images[0].url" class="img">
      <p>{{ track.name }}</p>
      <span class="spacer"></span>
      <span class="icon">
        <img :src="require('@/assets/img/spotify icon.png')" />
      </span>
    </div>
  </div>
</template>

<script>
import spotify from "../spotify"
import { mapState } from "vuex"

export default {
  name: 'CompareView',
  computed: mapState(['compare']),
  data() {
    return {
      first: {},
      second: {},
      loaded: false
    }
  },
  async mounted() {
    this.first = await this.load(this.compare[0])
    this.second = await this.load(this.compare[1])
    let uris = this.second.tracks.map(track => track.uri)
    this.first.tracks = this.first.tracks.filter(track => !uris.includes(track.uri))
    this.loaded = true
  },
  methods: {
    async load(id) {
      let tracks = []
      let res = await spotify.get(`https://api.spotify.com/v1/playlists/${id}`)
      let name = res.name
      res = res.tracks
      for (let track of res.items) {
        tracks.push(track.track)
      }
      while (res.next) {
        res = await spotify.get(res.next)
        for (let track of res.items) {
          tracks.push(track.track)
        }
      }
      return { tracks, name }
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  margin: 2em;
  margin-bottom: 1em;
}

h2 {
  margin-bottom: 1em;
}

.artist {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
  padding: 1em;
  background: #dcdcdc;
  flex: 1;
  border-radius: 1em;

  * {
    padding: .25em;
  }

  p {
    font-size: 1em;
    text-align: center;
  }
}

#playlist {
  .artist {
    flex-direction: column;
  }
}

#main {
  display: flex;
  flex-direction: column;

  .artist:not(:last-child) {
    margin-bottom: 0;
  }
}

.img {
  width: 3em;
}
</style>