function check(popup) {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      try {
        popup.location.href
        const token = popup.location.hash.split("#access_token=")[1].split("&")[0]
        const expiry = Number(popup.location.hash.split("&expires_in=")[1].split("&")[0])
        clearInterval(interval)
        popup.close()
        if (token) {
          resolve({token, expiry})
        } else {
          resolve({token: false})
        }
      } catch {
        // Callback not yet visited
      }
    }, 500)
  })
}

class Spotify {

  constructor() {
    this.token = {token: false}
  }

  async login() {
    const client_id = '584d9a8edd704543bcb7b4f3481303c0';
    const redirect_uri = window.location.href.includes("localhost") ? 'http://localhost:8080/callback' : 'https://playlist-analyzer.wrenn.me/callback';
    const scope = 'playlist-read-private';
    let url = 'https://accounts.spotify.com/authorize';
    url += '?response_type=token';
    url += '&client_id=' + encodeURIComponent(client_id);
    url += '&scope=' + encodeURIComponent(scope);
    url += '&redirect_uri=' + encodeURIComponent(redirect_uri);
    url += '&show_dialog=true';
    const popup = window.open(url, "", { popup: true })
    popup.resizeTo(500, 1000)
    popup.moveTo((window.outerWidth / 2) - 250, 0)
    const token = await check(popup)
    this.token = token
    return token
  }

  restore(expiry, token) {
    if (token != "" && (new Date()).getTime() < expiry.getTime()) {
      this.token.token = token
      return true
    } else {
      return false
    }
  }

  async makefetch(url, method, headers, body) {
    const res = await fetch(url, {
      method,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${this.token.token}`, ...headers },
      body: body ? JSON.stringify(body) : undefined
    })
    return await res.json()
  }

  get(url, headers) {
    return this.makefetch(url, "GET", headers, false)
  }

  post(url, headers, body) {
    return this.makefetch(url, "POST", headers, body)
  }
}

const instance = new Spotify()

export default instance