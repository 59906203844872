<template>
  <div class="flex">
    <h1>Playlist Analyzer</h1>
    <div class="spacer"></div>
    <div class="button" @click="login" v-if="!user">Login</div>
    <div class="button listButton" @click="listView ? listView = false : listView = true" v-if="view != 'list' && checked && view != 'compare'">Toggle List View</div>
    <div class="button home" @click="home" v-if="view != 'list' && checked">Home</div>
    <div class="button" @click="logout" v-if="user">Logout</div>
  </div>
  <PlaylistList v-if="user && view == 'list' && checked"></PlaylistList>
  <Playlist v-if="user && view == 'playlist' && checked" :listView="listView"></Playlist>
  <PlaylistYears v-if="user && view == 'playlist-years' && checked" :listView="listView"></PlaylistYears>
  <Compare v-if="user && view == 'compare' && checked"></Compare>
  <Genre v-if="user && view == 'playlist-genres' && checked" :listView="listView"></Genre>
  <div v-if="!user" class="main">
    <p>Welcome to Playlist Analyzer - a free tool (not affiliated with Spotify) to get useful insights into the music in
      your playlists.</p>
    <p>To see how we use your data, see our <router-link to="/privacy">Privacy Policy</router-link>.</p>
    <p>Playlist Analyzer currently has three views for each playlist. One to provide details of the number of tracks by artist, one to show the number of tracks by decade, and another by genre. You can also select two playlists to compare and get a list of the unique tracks.</p>
    <p>Content and associated metatdata displayed in Playlist Analyzer is supplied and made available by Spotify.</p>
    <img class="logo" :src="require('@/assets/img/spotify logo.png')"/>
  </div>
</template>

<script>
import spotify from "../spotify"
import { mapState } from "vuex"
import PlaylistList from "../components/PlaylistList.vue"
import Playlist from "../components/Playlist.vue"
import PlaylistYears from "../components/PlaylistYears.vue"
import Compare from "../components/Compare.vue"
import Genre from "../components/Genre.vue"

export default {
  name: 'HomePage',
  computed: mapState(['user', 'view', 'expiry', 'token']),
  data() {
    return {
      checked: false,
      listView: false
    }
  },
  components: {
    PlaylistList,
    Playlist,
    PlaylistYears,
    Compare,
    Genre
  },
  methods: {
    home() {
      this.$store.commit("setView", "list")
    },
    async login() {
      const accessToken = await spotify.login()
      this.$store.commit("setToken", accessToken.token)
      let expiry = new Date()
      expiry.setTime(expiry.getTime() + (accessToken.expiry * 1000))
      this.$store.commit("setExpiry", expiry)
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(this.logout, accessToken.expiry * 1000)
    },
    logout() {
      this.$store.commit("setToken", "")
    }
  },
  mounted() {
    let expiry = new Date(this.expiry)
    this.$store.commit("setView", "list")
    if (!spotify.restore(expiry, this.token)) {
      this.logout()
    } else {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(this.logout, expiry.getTime() - (new Date()).getTime())
    }
    this.checked = true
  }
}
</script>

<style lang="scss" scoped>
.flex {
  background: black;
  padding: 1em;

  h1 {
    color: white;
  }

  .home, .listButton {
    margin-right: .5em;
  }
}

.logo {
  display: block;
  margin: auto;
  margin-top: 4em;
  height: 50px;
}

@media only screen and (max-width: 1100px) {
  .listButton {
    display: none !important;
  }
}
</style>