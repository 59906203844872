<template>
  <div id="responsive">
    <p v-if="!loaded" id="loading">Loading...</p>
    <div id="mobile" :class="{ mobile: !listView }">
      <div v-for="genre in fullGenres" class="genre" :key="genre.name + 'mobile'">
        <img class="img" :src="genre.image">
        <p>{{ genre.name }}</p>
        <span class="spacer"></span>
        <span class="icon">
          <img :src="require('@/assets/img/spotify icon.png')" />
          <p class="count">{{ genre.count }}</p>
        </span>
      </div>
    </div>
    <div v-if="!listView" id="playlist" class="playlist">
      <div v-for="genre in genres" class="genre" :key="genre.name" :style="{ 'height': `${genre.height}%` }">
        <img class="img" :src="genre.image">
        <p>{{ genre.name }}</p>
        <span class="icon">
          <img :src="require('@/assets/img/spotify icon.png')" />
          <p class="count">{{ genre.count }}</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import spotify from "../spotify"
import { mapState } from "vuex"

export default {
  name: 'GenreView',
  computed: mapState(['playlist']),
  props: ['listView'],
  data() {
    return {
      genres: [],
      fullGenres: [],
      loaded: false
    }
  },
  async mounted() {
    let tracks = []
    let artists = {}
    let genres = {}
    let res = (await spotify.get(`https://api.spotify.com/v1/playlists/${this.playlist}/tracks`))
    for (let track of res.items) {
      tracks.push(track.track)
    }
    while (res.next) {
      res = await spotify.get(res.next)
      for (let track of res.items) {
        tracks.push(track.track)
      }
    }
    for (let track of tracks) {
      if (artists[track.artists[0].id]) {
        artists[track.artists[0].id].count++
      } else {
        artists[track.artists[0].id] = {
          count: 1,
          name: track.artists[0].name,
          image: track.album.images[0].url,
          use: true
        }
      }
    }
    if (tracks.length > 400) {
      for (let id in artists) {
        if (artists[id].count < 3) {
          artists[id].use = false
        }
      }
    }
    for (let id in artists) {
      if (artists[id].use) {
        let artist = await spotify.get(`https://api.spotify.com/v1/artists/${id}`)
        //for (let genre of artist.genres) {
        let genre = artist.genres[0]
        if (genre) {
          if (genres[genre]) {
            genres[genre].count += artists[id].count
          } else {
            genres[genre] = {
              count: artists[id].count,
              name: genre.split(" ").map(word => word[0].toUpperCase() + word.substr(1)).join(" "),
              image: artists[id].image
            }
          }
        }
        //}
      }
    }
    let genresArr = []
    for (let id in genres) {
      genresArr.push(genres[id])
    }
    genresArr.sort((a, b) => b.count - a.count)
    this.fullGenres = genresArr
    genresArr = genresArr.slice(0, 6)
    const sum = genresArr.reduce((a, b) => a + b.count, 0)
    for (let genre of genresArr) {
      genre.percent = Math.log(genre.count / sum)
      genre.height = 100 - (-genre.percent * (100 / Math.log(100)))
      if (genre.height < 40) genre.height = 40
    }
    this.genres = genresArr
    this.loaded = true
  }
}
</script>

<style lang="scss" scoped>
#loading {
  text-align: center;
  margin: 2em;
  font-size: 1.5em;
}

#playlist {
  justify-content: center;
  align-items: flex-end;
  padding: 2em;
  height: 90vh;
}

.genre {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
  padding: 1em;
  background: #dcdcdc;
  flex: 1;
  border-radius: 1em;

  * {
    padding: .5em;
  }

  p {
    font-size: 1.2em;
    text-align: center;
  }

  .count {
    font-size: 1.5em;
  }

  img {
    max-width: 8em;
  }
}

#playlist {
  .genre {
    flex-direction: column;
  }
}

.playlist {
  display: flex;
}

.mobile {
  display: none;
}

#mobile {
  flex-direction: column;

  .genre:not(:last-child) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .playlist {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }
}

@media only screen and (max-width: 700px) {
  .img {
    width: 3em;
  }

  .genre {
    * {
      padding: .25em;
    }

    p:not(.count) {
      font-size: 1em;
    }
  }
}
</style>