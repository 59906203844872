<template>
  <div id="responsive">
    <div id="list" class="mobile">
      <div v-for="list in playlists" :key="list.id + 'mobile'" class="flex">
        <div class="stack">
          <img :src="list.images[0].url">
          <p>{{ list.name }}</p>
          <div v-if="!compare.includes(list.id)" class="button" @click="select(list.id)">
            {{ selecting ? "Compare with Selected" : "Compare" }}
          </div>
        </div>
        <img class="logo" :src="require('@/assets/img/spotify icon.png')" />
        <div class="spacer"></div>
        <div class="stack">
          <div class="button" @click="analyze(list.id)">Artists</div>
          <div class="button" @click="analyze(list.id, true)">Years</div>
          <div class="button" @click="analyze(list.id, false, true)">Genres</div>
        </div>
      </div>
    </div>
    <div id="list" class="desktop">
      <div v-for="list in playlists" :key="list.id" class="flex">
        <img :src="list.images[0].url">
        <p>{{ list.name }}</p>
        <img class="logo" :src="require('@/assets/img/spotify logo.png')" />
        <div class="spacer"></div>
        <div v-if="!compare.includes(list.id)" class="button" @click="select(list.id)">
          {{ selecting ? "Compare with Selected" : "Compare" }}
        </div>
        <div class="button" @click="analyze(list.id)">Artists</div>
        <div class="button" @click="analyze(list.id, true)">Years</div>
        <div class="button" @click="analyze(list.id, false, true)">Genres</div>
      </div>
    </div>
  </div>
</template>

<script>
import spotify from "../spotify"

export default {
  name: 'PlaylistList',
  data() {
    return {
      playlists: [],
      selecting: false,
      compare: []
    }
  },
  methods: {
    analyze(id, years = false, genres = false) {
      this.$store.commit("setPlaylist", id)
      if (years) {
        this.$store.commit("setView", "playlist-years")
      } else if (genres) {
        this.$store.commit("setView", "playlist-genres")
      } else {
        this.$store.commit("setView", "playlist")
      }
    },
    select(id) {
      if (this.selecting) {
        this.compare.push(id)
        this.selecting = false
        this.$store.commit("setCompare", this.compare)
        this.compare = []
        this.$store.commit("setView", "compare")
      } else {
        this.selecting = true
        this.compare = [id]
      }
    }
  },
  async mounted() {
    this.playlists = (await spotify.get("https://api.spotify.com/v1/me/playlists")).items.map(list => {
      if (list.images.length == 0) {
        list.images.push({ url: require('@/assets/img/spotify icon.png') })
      }
      return list
    })
  }
}
</script>

<style lang="scss" scoped>
#list {
  padding: 1em;
  margin-top: .5em;

  .flex {
    background: #dcdcdc;
    border-radius: 1em;
    padding: 1em;
    margin: 1em;

    *:not(img) {
      padding: 1em;
    }
  }

  .button {
    margin-right: 1em;

    &:hover {
      background: #a9a9a9;
      color: black;
      border: 2px solid black;
    }
  }
}

.stack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div:not(:last-child) {
    margin-bottom: 0;
  }
}

.desktop {
  display: block;

  img {
    max-width: 5em;
  }
}

.mobile {
  display: none;

  img {
    max-width: 5em;

    &.logo {
      max-width: 2em;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 700px) {
  .stack {
    flex-direction: column;

    div:not(:last-child) {
      margin-bottom: .5em;
    }
  }

  .spacer {
    display: none;
  }

  .flex {
    padding: .5em;
  }
}
</style>