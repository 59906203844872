import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    token: "",
    user: false,
    view: "list",
    playlist: "",
    expiry: new Date(),
    compare: []
  },
  getters: {
  },
  mutations: {
    setToken(state, val) {
      state.token = val
      state.user = val != ""
    },
    setExpiry(state, val) {
      state.expiry = val
    },
    setView(state, val) {
      state.view = val
    },
    setPlaylist(state, val) {
      state.playlist = val
    },
    setCompare(state, val) {
      state.compare = val
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
