<template>
  <div id="responsive">
    <div id="mobile" :class="{mobile: !listView}">
      <div v-for="decade in fullDecades" class="decade" :key="decade.name + 'mobile'">
        <img class="img" :src="decade.image">
        <p>{{ decade.name }}</p>
        <span class="spacer"></span>
        <span class="icon">
          <img :src="require('@/assets/img/spotify icon.png')" />
          <p class="count">{{ decade.count }}</p>
        </span>
      </div>
    </div>
    <div v-if="!listView" id="playlist" class="playlist">
      <div v-for="decade in decades" class="decade" :key="decade.name" :style="{ 'height': `${decade.height}%` }">
        <img class="img" :src="decade.image">
        <p>{{ decade.name }}</p>
        <span class="icon">
          <img :src="require('@/assets/img/spotify icon.png')" />
          <p class="count">{{ decade.count }}</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import spotify from "../spotify"
import { mapState } from "vuex"

function toDecade(year) {
  return `${year.substring(0, 3)}0`
}

export default {
  name: 'PlayList',
  computed: mapState(['playlist']),
  props: ['listView'],
  data() {
    return {
      decades: [],
      fullDecades: []
    }
  },
  async mounted() {
    let tracks = []
    let decades = {}
    let res = (await spotify.get(`https://api.spotify.com/v1/playlists/${this.playlist}`)).tracks
    for (let track of res.items) {
      tracks.push(track.track)
    }
    while (res.next) {
      res = await spotify.get(res.next)
      for (let track of res.items) {
        tracks.push(track.track)
      }
    }
    for (let track of tracks) {
      let decade = toDecade(track.album.release_date.split("-")[0])
      if (decades[decade]) {
        decades[decade].count++
      } else {
        decades[decade] = {
          count: 1,
          name: decade + "s",
          image: track.album.images[0].url
        }
      }
    }
    let decadesArr = []
    for (let id in decades) {
      decadesArr.push(decades[id])
    }
    decadesArr.sort((a, b) => b.count - a.count)
    this.fullDecades = decadesArr
    decadesArr = decadesArr.slice(0, 6)
    const sum = decadesArr.reduce((a, b) => a + b.count, 0)
    for (let decade of decadesArr) {
      decade.percent = Math.log(decade.count / sum)
      decade.height = 100 - (-decade.percent * (100 / Math.log(100)))
      if (decade.height < 40) decade.height = 40
    }
    this.decades = decadesArr
  }
}
</script>

<style lang="scss" scoped>
#playlist {
  justify-content: center;
  align-items: flex-end;
  padding: 2em;
  height: 90vh;
}

.decade {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
  padding: 1em;
  background: #dcdcdc;
  flex: 1;
  border-radius: 1em;

  * {
    padding: .5em;
  }

  p {
    font-size: 1.2em;
    text-align: center;
  }

  .count {
    font-size: 1.5em;
  }

  img {
    max-width: 8em;
  }
}

#playlist {
  .decade {
    flex-direction: column;
  }
}

.playlist {
  display: flex;
}

.mobile {
  display: none;
}

#mobile {
  flex-direction: column;
  .decade:not(:last-child) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .playlist {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }
}

@media only screen and (max-width: 700px) {
  .img {
    width: 3em;
  }

  .decade {
    * {
      padding: .25em;
    }
    p:not(.count) {
      font-size: 1em;
    }
  }
}
</style>